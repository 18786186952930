import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import AuthModule from '@/store/modules/auth';
import { abilities } from '@/plugins/casl/abilities';
import { cmsAdminBaseUrl, getViewAdminCMSPages, getCMSComponents } from '@/utils/cms';

Vue.use(VueRouter);

// Add static routes
const routes: Array<RouteConfig> = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/account/Login.vue')
    },
    {
        path: cmsAdminBaseUrl,
        name: 'cmsAdmin',
        component: () => import('@/views/neuhaus-website/cms/admin/CMSAdmin.vue'),
        meta: {
            requiresLogin: true
        },
        children: [
            {
                path: 'pages',
                name: 'cmsAdminPages',
                component: () => import('@/views/neuhaus-website/cms/admin/CMSAdminPages.vue')
            },
            {
                path: 'components',
                name: 'cmsAdminComponents',
                component: () => import('@/views/neuhaus-website/cms/admin/CMSAdminComponents.vue')
            }
        ]
    },
    {
        path: '/contact',
        name: 'contactPage',
        component: () => import('@/views/neuhaus-website/ContactPage.vue')
    },
    {
        path: '/imprint',
        name: 'imprint',
        component: () => import('@/views/neuhaus-website/Imprint.vue')
    },
    {
        path: '/privacy-policy',
        name: 'privacyPolicy',
        component: () => import('@/views/neuhaus-website/PrivacyPolicy.vue')
    },
    {
        path: '*',
        redirect: { name: 'home' }
    }
];

// Add cms page routes
getViewAdminCMSPages().forEach(page => {
    // add view to router
    routes.push({
        path: page.view.url,
        name: page.view.routeName,
        component: () => import(`@/views/neuhaus-website/cms/pages/${page.view.component}`)
    });

    // add admin page to router
    if (page.admin) 
    {
        routes.push({
            path: page.admin.url,
            name: page.admin.routeName,
            component: () => {
                if (page.admin) {
                    return import(`@/views/neuhaus-website/cms/admin/pages/${page.admin.component}`);
                }

                return import('@/components/cms/pages/CMSPage.vue');
            },
            meta: {
                requiresLogin: true,
                requiredPermission: {
                    action: 'update',
                    subject: 'Page'
                }
            }
        });
    }
});

// Add cms admin component routes
getCMSComponents().forEach(component => {
    // add admin page to router
    if (component.admin) 
    {
        routes.push({
            path: component.admin.url,
            name: component.admin.routeName,
            component: () => {
                if (component.admin) {
                    return import(`@/views/neuhaus-website/cms/admin/components/${component.admin.component}`);
                }

                return import('@/components/cms/pages/CMSPage.vue');
            },
            meta: {
                requiresLogin: true,
                requiredPermission: {
                    action: 'update',
                    subject: 'Component'
                }
            }
        });
    }
});

const router = new VueRouter({
    routes
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresLogin && !AuthModule.isAuthenticated)) {
        next({ name: 'login', query: { nextUrl: to.fullPath } });
    }
    else if(to.matched.some(record => record.meta.requiredPermission && record.meta.requiredPermission.action && record.meta.requiredPermission.subject && !abilities.can(record.meta.requiredPermission.action, record.meta.requiredPermission.subject))) {
        next(false);
    }
    else {
        next();
    }
});

export default router;
