import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import de from 'vuetify/src/locale/de';

// Installs the vuetify plugin
Vue.use(Vuetify);

// Exports a new vuetify instance
export default new Vuetify({
    lang: {
        locales: { de },
        current: "de"
    }
});
