import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import './plugins/casl/vue';
import AuthModule from './store/modules/auth';

// Fonts
import "@mdi/font/css/materialdesignicons.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

Vue.config.productionTip = false;


// Tries to log the user on automatically
AuthModule.tryAutoLogin();

// Creates a new Vue instance
new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app');
