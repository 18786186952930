




















import { Component, Vue } from 'vue-property-decorator';

/**
 * App component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'app-header': () => import('@/components/Header.vue'),
        'app-alert': () => import('@/components/Alert.vue'),
        'app-footer': () => import('@/components/Footer.vue')
    }
})
export default class App extends Vue {
}
