import { VuexModule, Module, getModule, Mutation, Action } from 'vuex-module-decorators';
import { User, UserCredentials } from '@/types/account';
import store from '@/store/';
import axios, { APIResponse } from '@/plugins/axios';
import router from '@/router';
import { updateAbilities } from '@/plugins/casl/abilities';

/**
 * VuexModule for user authentication
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Module({ store: store, namespaced: true, name: 'auth', dynamic: true })
class AuthModule extends VuexModule {
    // Property of type User (can be null)
    private _user: User | null = null;

    /**
     * Assigns the given value to _user 
     * 
     * @param user user object
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private SET_USER(user: User) {
        this._user = user;
    }

    /** 
     * Assigns null to _user
     * 
     * @author Kevin Danne <danne@skiba-proocmputer.de>
     */
    @Mutation
    private RESET_USER() {
        this._user = null;
    }

    /**
     * Calls SET_USER, stores the token in localStorage, sets the Authorization header, update abilities
     * 
     * @param user user object
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    private setAuthorizationHeaderAndUser(user: User) {
        this.SET_USER(user);
        localStorage.setItem('token', user.token);
        axios.defaults.headers.common['Authorization'] = 'Token ' + user.token;

        if (this._user != null && this._user.permissions != null) {
            updateAbilities(this._user.permissions.map(val => val.permissionName));
        }
    }

    /** 
     * Redirects the user to nextUrl if set, otherwise the user is redirected to LOGIN_REDIRECT_URLNAME set in the .env file
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    private redirectUser() {
        if (router.currentRoute.query.nextUrl) {
            router.push(router.currentRoute.query.nextUrl as string);
        } else {
            router.push({ name: process.env.VUE_APP_LOGIN_REDIRECT_URLNAME });
        }
    }
    
    /**
     * Checks the UserCredentials and logs the user on
     * 
     * @param userCredentials UserCredentials object
     * 
     * @returns promise with response data
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action({rawError: true})
    public login(userCredentials: UserCredentials): Promise<void> {
        return new Promise((resolve, reject) => {
            axios.post<APIResponse<User>>('/user/login', userCredentials)
            .then(response => {
                if (response.data.status == 'success' && response.data.data) {
                    this.setAuthorizationHeaderAndUser(response.data.data);
                    this.redirectUser();

                    return resolve();
                }
                return reject(response.data);
            })
            .catch((response) => {
                return reject(response.data);
            });
        });
    }

    /** 
     * Checks if a token is set in the localStorage and if it is still valid 
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public tryAutoLogin() {
        const token = localStorage.getItem('token');

        if (token) {
            axios.post<APIResponse<User>>('/user/checktoken', {token: token})
            .then((response) => {
                if (response.data.status == 'success' && response.data.data) {
                    this.setAuthorizationHeaderAndUser(response.data.data);

                    if (router.currentRoute.query.nextUrl) {
                        this.redirectUser();
                    }
                }
            });
        }
    }

    /** 
     * Logs the user out
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public logout() {
        localStorage.removeItem('token');
        delete axios.defaults.headers.common['Authorization'];
        this.RESET_USER();
        updateAbilities([]);

        router.push({ name: 'login' });
    }

    /**
     * @returns _user if set, otherwise null will be returned
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    public get user(): User | null {
        return this._user;
    }

    /** 
     * @returns a boolean that represents whether the user is logged in 
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    public get isAuthenticated(): boolean {
        return this._user != null;
    }
}

// Export AuthModule
export default getModule(AuthModule);
