import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';

// Installs the VueI18n plugin
Vue.use(VueI18n);

/**
 * Loads the global locales and parses them to i18n messages
 * 
 * @returns parsed i18n messages
 * 
 * @author Vuetfiy
 */
function loadLocaleMessages(): LocaleMessages {
    const locales = require.context('../../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages: LocaleMessages = {}
    
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    });

    return messages;
}

// Exports a new VueI18n instance
export default new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'de',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
    messages: loadLocaleMessages(),
    silentTranslationWarn: true
});
