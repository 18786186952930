import { CMSPage, ViewAdminCMSPage, ViewAdminCMSComponent } from '@/types/cms/';

export const cmsAdminBaseUrl = '/admin/cms';

export const cmsCustomViewComponent = 'CMSPageCustom';

export const cmsCustomAdminViewComponent = 'CMSAdminCustom';

export const localCMSPages: ViewAdminCMSPage[] = [
    {
        view: {
            id: 0,
            name: 'Startseite',
            url: '/',
            routeName: 'home',
            component: 'CMSPageHome',
            showInNavbar: true,
            exactActiveHighlighting: true,
            isLocalPage: true
        },
        admin: {
            id: 1,
            name: 'Startseite Bearbeiten',
            url: `${cmsAdminBaseUrl}/pages/home/edit`,
            routeName: 'cmsAdminHome',
            component: 'CMSAdminHome',
            isLocalPage: true
        }
    },
    {
        view: {
            id: 2,
            name: 'Produkte',
            url: '/products',
            routeName: 'productList',
            showInNavbar: true,
            exactActiveHighlighting: false,
            component: 'products/CMSPageProductList',
            isLocalPage: true
        },
        admin: {
            id: 3,
            name: 'Produkte Bearbeiten',
            url: `${cmsAdminBaseUrl}/pages/products/edit`,
            routeName: 'cmsAdminProductList',
            component: 'CMSAdminProduct',
            isLocalPage: true
        }
    },
    {
        view: {
            id: 4,
            name: 'Produkte',
            url: '/products/:productId',
            routeName: 'productDetails',
            showInNavbar: false,
            exactActiveHighlighting: false,
            component: 'products/CMSPageProductDetails',
            isLocalPage: true
        },
        admin: undefined
    },
    {
        view: {
            id: 6,
            name: 'Zulassung/Qualität',
            url: '/approval-quality',
            routeName: 'approvalQuality',
            showInNavbar: true,
            exactActiveHighlighting: false,
            component: 'CMSPageApprovalQuality',
            isLocalPage: true
        },
        admin: {
            id: 7,
            name: 'Zulassung/Qualität Bearbeiten',
            url: `${cmsAdminBaseUrl}/pages/approval-quality/edit`,
            routeName: 'cmsAdminApprovalQuality',
            component: 'CMSAdminApprovalQuality',
            isLocalPage: true
        }
    },
    {
        view: {
            id: 8,
            name: 'Geschichte',
            url: '/history',
            routeName: 'history',
            showInNavbar: true,
            exactActiveHighlighting: false,
            component: 'CMSPageHistory',
            isLocalPage: true
        },
        admin: {
            id: 9,
            name: 'Geschichte Bearbeiten',
            url: `${cmsAdminBaseUrl}/pages/history/edit`,
            routeName: 'cmsAdminHistory',
            component: 'CMSAdminHistory',
            isLocalPage: true
        }
    },
    {
        view: {
            id: 10,
            name: 'Materialien',
            url: '/materials',
            routeName: 'materials',
            showInNavbar: true,
            exactActiveHighlighting: false,
            component: 'CMSPageMaterials',
            isLocalPage: true
        },
        admin: {
            id: 11,
            name: 'Materialien Bearbeiten',
            url: `${cmsAdminBaseUrl}/pages/materials/edit`,
            routeName: 'cmsAdminMaterials',
            component: 'CMSAdminMaterials',
            isLocalPage: true
        }
    },
    {
        view: {
            id: 12,
            name: 'Stellenanzeigen',
            url: '/job-advertisements',
            routeName: 'jobAdvertisement',
            showInNavbar: true,
            exactActiveHighlighting: false,
            component: 'CMSPageJobAdvertisement',
            isLocalPage: true
        },
        admin: {
            id: 13,
            name: 'Stellenanzeigen Bearbeiten',
            url: `${cmsAdminBaseUrl}/pages/advertisement/edit`,
            routeName: 'cmsAdminJobAdvertisement',
            component: 'CMSAdminJobAdvertisement',
            isLocalPage: true
        }
    }
];

export const localCMSComponents: ViewAdminCMSComponent[] = [
    {
        view: {
            id: 1,
            name: 'Carousel'
        },
        admin: {
            id: 1,
            name: 'Carousel Bearbeiten',
            url: `${cmsAdminBaseUrl}/components/carousel/edit`,
            routeName: 'cmsAdminComponentCarousel',
            component: 'CMSAdminComponentCarousel',
            isLocalComponent: true
        }
    }
];

/**
 * @returns local cms pages + cms pages from API
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
export function getCMSPages(): CMSPage[] {
    const cmsPages: CMSPage[] = [];

    // Add local cms pages to array
    localCMSPages.forEach(page => {
        // Add view to array
        cmsPages.push({
            id: page.view.id,
            name: page.view.name,
            url: page.view.url,
            routeName: page.view.routeName,
            showInNavbar: page.view.showInNavbar,
            exactActiveHighlighting: page.view.exactActiveHighlighting,
            component: page.view.component,
            isLocalPage: page.view.isLocalPage
        });
        
        // Add admin view to array
        if (page.admin) {
            cmsPages.push({
                id: page.admin.id,
                name: page.admin.name,
                url: page.admin.url,
                routeName: page.admin.routeName,
                showInNavbar: false,
                exactActiveHighlighting: false,
                component: page.admin.component,
                isLocalPage: page.admin.isLocalPage
            });
        }
    });

    // TODO fetch cms pages from API and add it to array

    return cmsPages;
}

/**
 * @returns local cms pages + cms pages from API + admin views
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
export function getViewAdminCMSPages(): ViewAdminCMSPage[] {
    const cmsPages: ViewAdminCMSPage[] = localCMSPages;

    // TODO add cms pages + admin views from api
    
    return cmsPages;
}

/**
 * @returns local cms components + cms components from API
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
export function getCMSComponents(): ViewAdminCMSComponent[] {
    const cmsComponents: ViewAdminCMSComponent[] = localCMSComponents;

    // TODO add cms components from api
    
    return cmsComponents;
}
