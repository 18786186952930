import { Ability, AbilityBuilder } from '@casl/ability';

const abilities = new Ability([]);
const actionSubjectRegex = /(create|read|update|delete)([a-zA-Z]+)/;

/**
 * Parse CRUD strings to CASL permissions and saves them
 * 
 * @param permissions an array of CRUD permission strings
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
export function updateAbilities(permissions: Array<string>) {
    const { can, rules } = new AbilityBuilder<Ability>(Ability);
    
    for (let i = 0; i < permissions.length; i++) {
        const actionSubject = permissions[i].match(actionSubjectRegex);
        if (actionSubject != null && actionSubject.length == 3) {
            can(actionSubject[1], actionSubject[2]);
        }
    }

    abilities.update(rules);
}

export { abilities };